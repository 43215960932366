<template>
    <div class="home_page">
        <div class="home_page_img" ></div>
    </div>
</template>

<script>
import { TimelineMax } from 'gsap'

export default {
    name: 'HomePageImg',
    components: {
    },
    data() {
        return {
            init: 1,
            clickable: true
        };
    },
    
    mounted () {
        const home_page = document.querySelector(".move") 
        home_page.addEventListener('click', this.clickAnim);

        const home_page_rupture = document.querySelector(".home_page_rupture") 
        home_page_rupture.addEventListener('click', (event) => {
            stoppingPropagation(event)
        });

        const home_page_item = document.querySelectorAll(".home_page_item") 
        home_page_item.forEach(element => {
            element.addEventListener('click', (event) => {
                stoppingPropagation(event)
            });
        });
        
        function stoppingPropagation(event) {
            event.stopPropagation();
        }
    },
    
    methods: {
    
        clickAnim: function() {
            const vm = this

            if (this.clickable) {
                this.init++
            
                if (this.init > 5) {
                    this.init = 1
                }

                this.changeImg(this.init, () => { vm.clickable = true })
            }
            this.clickable = false
        },

        changeImg: function(value, callback) {
            let tl = new TimelineMax({onComplete: callback })
            tl.to(".home_page_img", 0, {css: {backgroundImage:  "url(/img/" + value +".jpg)"}, ease:"Power4.easeInOut"})
            tl.from(".home_page_img", 1, {css: {top: "100%", scale: 1.85}, ease:"Power4.easeInOut"}, "-=0.15")
        }
    }
}
</script>

<style lang="scss" scoped>
.home_page {
    &_img {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-image: url("/img/1.jpg");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transform: scale(0.7), translateZ(0px);
    }
}
</style>
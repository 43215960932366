<template>
    <Website />
</template>

<script>
import Website from '@/components/Website.vue'

export default {
  name: 'Home',
  components: {
    Website,
  }
}
</script>
<template>
    <div class="audioManager">
        <label for="slider"></label>
        <input class="slider" type="range" 
                min="0" 
                id="slider"
                :max="time.max" 
                step="0.01"
                @change="onChange"
                @input="onInput"
                v-model="time.value"
        >

        <div class="audioManager_manager">
            <!-- Prev -->
            <button :disabled="disabled" @click="onNextPrevious('previous')" :class="{'disabled':disabled}" aria-label="Previous music">
                <svg class="audioManager_img_previous button_disabled" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="30" height="30" x="0" y="0" viewBox="0 0 416.004 416.004" style="enable-background:new 0 0 512 512" xml:space="preserve" ><g transform="matrix(-1,-1.2246467991473532e-16,1.2246467991473532e-16,-1,416.003999710083,416.00442600250244)">
                    <g >
                        <g>
                            <path d="M281.602,195.204l-256-192C20.802-0.444,14.274-1.02,8.866,1.7c-5.44,2.72-8.864,8.256-8.864,14.304v384    c0,6.048,3.424,11.584,8.832,14.304c2.272,1.12,4.736,1.696,7.168,1.696c3.392,0,6.784-1.088,9.6-3.2l256-192    c4.032-3.008,6.4-7.776,6.4-12.8S285.634,198.212,281.602,195.204z" data-original="#000000" />
                        </g>
                    </g>
                    <g >
                        <g>
                            <path d="M400.002,0.004h-32c-8.832,0-16,7.168-16,16v384c0,8.832,7.168,16,16,16h32c8.832,0,16-7.168,16-16v-384    C416.002,7.172,408.834,0.004,400.002,0.004z" data-original="#000000" />
                        </g>
                    </g>
                    </g>
                </svg>
            </button>

            <!-- Play -->
            <button class="audioManager_img_play-stop" @click="playOrStopMusic" aria-label="Play or Stop">
                <svg v-if="playing" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="30" height="30" x="0" y="0" viewBox="0 0 519.479 519.479" style="enable-background:new 0 0 512 512" xml:space="preserve">
                    <g>
                        <path d="M193.441,0h-75.484c-16.897,0-30.6,13.703-30.6,30.6v458.277c0,16.898,13.703,30.602,30.6,30.602h75.484    c16.897,0,30.6-13.703,30.6-30.602V30.6C224.042,13.703,210.339,0,193.441,0z" data-original="#000000" style="" />
                        <path d="M401.521,0h-75.484c-16.896,0-30.6,13.703-30.6,30.6v458.277c0,16.898,13.703,30.602,30.6,30.602h75.484    c16.896,0,30.6-13.703,30.6-30.602V30.6C432.121,13.703,418.418,0,401.521,0z" data-original="#000000" style="" />
                    </g>
                </svg>

                <svg v-else xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="30" height="30" x="0" y="0" viewBox="0 0 320.001 320.001" style="enable-background:new 0 0 512 512" xml:space="preserve" >
                    <g>
                        <path xmlns="http://www.w3.org/2000/svg" d="M295.84,146.049l-256-144c-4.96-2.784-11.008-2.72-15.904,0.128C19.008,5.057,16,10.305,16,16.001v288  c0,5.696,3.008,10.944,7.936,13.824c2.496,1.44,5.28,2.176,8.064,2.176c2.688,0,5.408-0.672,7.84-2.048l256-144  c5.024-2.848,8.16-8.16,8.16-13.952S300.864,148.897,295.84,146.049z" fill="#000" data-original="#000000" style=""/>
                    </g>
                </svg>
            </button>

            <!-- Next -->
            <button :disabled="disabled" @click="onNextPrevious('next')" :class="{'disabled':disabled}" aria-label="Next music">
                <svg class="audioManager_img_next button_disabled"  xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="30" height="30" x="0" y="0" viewBox="0 0 416.004 416.004" style="enable-background:new 0 0 512 512" xml:space="preserve" ><g>
                <g >
                    <g>
                        <path d="M281.602,195.204l-256-192C20.802-0.444,14.274-1.02,8.866,1.7c-5.44,2.72-8.864,8.256-8.864,14.304v384    c0,6.048,3.424,11.584,8.832,14.304c2.272,1.12,4.736,1.696,7.168,1.696c3.392,0,6.784-1.088,9.6-3.2l256-192    c4.032-3.008,6.4-7.776,6.4-12.8S285.634,198.212,281.602,195.204z" data-original="#000000" />
                    </g>
                </g>
                <g >
                    <g>
                        <path d="M400.002,0.004h-32c-8.832,0-16,7.168-16,16v384c0,8.832,7.168,16,16,16h32c8.832,0,16-7.168,16-16v-384    C416.002,7.172,408.834,0.004,400.002,0.004z" data-original="#000000" />
                    </g>
                </g>
                </g>
                </svg>
            </button>
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'AudioManager',
    props: { music: Object },
    data() {
        return {
            disabled: true,
            current: 0,
            audio: new Audio(),
            time: {
                value: 0,
                max: 0,
                end: false,
                change: false
            },
            playing: false
        }
    },
    methods: {
        
        onNextPrevious: function(event) {
            this.$emit("onNextPrevious", { music: this.music, event })
        },
        onInput: function() {
            this.time.change = true
        },
        onChange: function() {
            this.time.change = false
            this.audio.currentTime = this.time.value
            this.time.end && this.audio.play()
        },

        setAudioPlayer: function(src) {
            if (src !== "") {
                this.audio.load()
                this.audio.src = src
            }
            this.audio.ontimeupdate = () => {
                this.time.end = false
                this.time.max = this.audio.duration.toFixed(2)
                !this.time.change && (this.time.value = this.audio.currentTime)
            }
            this.audio.onended = () => {
                this.time.end = true
                this.$emit("onEnded", {index: this.music.index, end: true})
            }
            if (src !== "" && this.audio.play()) {
                this.audio.play()
            }
            if (this.playing == false) {
                this.playing = !this.playing
            }
        },
        playOrStopMusic: function() {
            this.disabled = false

            if (this.playing === false) {
                this.playing = !this.playing
                
                if (this.audio.src == "") {
                    this.setAudioPlayer(this.music.src)
                    this.$emit("startMusic", this.music )
                }
                this.audio.play()
            }
            else {
                this.playing = !this.playing
                let playPromise = this.audio.play();

                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        this.audio.pause()
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            }
        }
    },
    mounted() {
    },
    watch: {
        music: function (value) {

            if (value.src !== undefined) {
                this.disabled = false
                this.setAudioPlayer(value.src)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
button {
    border: none;
    background: none;

    &:focus { 
        outline:0;
    }
    &:hover {
        cursor: pointer;
    }
}

.disabled {
    .button_disabled {
        opacity: 0.25;
        transition: .3s;

        &:hover {
            cursor: initial;
        }
    }
}

input {
    -webkit-appearance: none;
    width: 100%;
    height: 0.35rem;
    border-radius: 5px;  
    background: #222222;
    outline: none;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 23px;
        height: 23px;
        border-radius: 50%; 
        background: #d3d3d3;
        background: #000;
        opacity: 1;
        cursor: pointer;
    }
}
.audioManager {

    &_manager {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
    }
    &_img_play-stop{
        width: fit-content;
        margin: 0 4rem;
    }
}

@media screen and (min-width: 200px) and (max-width: 640px) {
    .audioManager {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
</style>
<template>
    <div class="website">
        <HomePage />
        <RupturePage @ChangeData="reActiveEventListener"/>
    </div>
</template>

<script>
import HomePage from '@/components/HomePage.vue'
import RupturePage from '@/components/RupturePage.vue'
import HomePageImg from '@/components/HomePageImg.vue'

export default {
    name: 'Home',
    props: ['showRuptureOrNot'],
    components: {
        HomePage,
        RupturePage,
        HomePageImg
    },
    data() {
        return {
            isMobile: false,
            mouse: {
                x: 0,
                y: 0
            },
            posX: 0,
            posY: 0,
            degX: 0,
            degY: 0,
            sensibility: 20,
            callEventListener: ""
        };
    },
    methods: {
        reActiveEventListener: function () {
            if (!this.isMobile) {
                this.callEventListener = event => this.animationImage(event)
                document.querySelector('.home_page').addEventListener("mousemove", this.callEventListener)
            }
        },
        
        animationImage: function(event) {
            const home_page_data = document.querySelector(".home_page_data");
            const home_page_img = document.querySelector(".home_page_img");

            this.mouse.x = event.clientX;
            this.mouse.y = event.clientY;

            this.posX =  ( this.mouse.x - (window.innerWidth/2) ) / (window.innerWidth/2) * 100;
            this.posY =  ( this.mouse.y - (window.innerHeight/2) ) / (window.innerHeight/2) * 100;

            this.degX = this.posX/this.sensibility;
            this.degY = this.posY/this.sensibility;

            home_page_data.style.transform = "rotateX("+ - this.degY +"deg) rotateY("+ this.degX +"deg) ";
            home_page_img.style.transform = "rotateX("+ - this.degY +"deg) rotateY("+ this.degX +"deg) ";
        },
        detectDevice: function () {
            var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            return isMobile
        },
        removeListeners: function () {
            if (this.myListeners) {
                for (var i = 0; i < this.myListeners.length; i++) {
                    this.removeEventListener(this.myListeners[i].eType, this.myListeners[i].callBack);
                };
            delete this.myListeners;
            };
        }
    },

    mounted() {
        if (!this.isMobile) {
            this.callEventListener = event => this.animationImage(event)
            document.querySelector('.home_page').addEventListener("mousemove", this.callEventListener)
        }
    },
    created() {
        this.isMobile = this.detectDevice() 
    }
}
</script>

<style lang="scss" scoped>

.website {
    text-transform: uppercase;
    font-weight: bold;
}

</style>
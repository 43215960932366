<template>
    <div class="home_page">
        <div class="move">
            <div class="home_page_border" />
            <HomePageImg />
            <div class="home_page_data">
                <h1>Airmow</h1>
                <ul class="home_page_data_list">
                    <li class="home_page_item grow"><a href="https://open.spotify.com/artist/6SfPAcR67ssVyMQWoizN7Q" target="_blank" rel="noopener">Spotify</a></li>
                    <li class="home_page_item grow"><a href="https://www.youtube.com/c/AIRMOW/" target="_blank" rel="noopener">Youtube</a></li>
                    <li class="home_page_item grow"><a href="https://www.instagram.com/airmowmusic/" target="_blank" rel="noopener">Instagram</a></li>
                    <li class="home_page_item grow"><a href="https://musics.airmow.fr/musics/PRESSKIT_AIRMOW.pdf" target="_blank" rel="noopener">Presskit</a></li>   
                </ul>
                <div class="home_page_rupture" @click="animHomeToRupture">
                    <h2 class="hoverable" showRuptureOrNot=true >Rupture</h2>
                    <p class="home_page_description grow">Listen to the ep here</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import HomePageImg from '@/components/HomePageImg.vue'

export default {
    name: 'HomePage',
    props: ['showRuptureOrNot'],
    components: {
        HomePageImg
    },
    methods: {
        
        animHomeToRupture: function () {
            
            if (!this.isMobile) {
                document.querySelector('.home_page').removeEventListener("mousemove", this.callEventListener);
            }

            let tl = gsap.timeline()
            tl.to(".rupture_Page", {duration: 0.5, css: {opacity: 1}, ease:"Power4.easeInOut"}, "-=0.5")
            tl.to(".home_page_img", {duration: 1, css: {opacity:0}, ease:"Power4.easeInOut" });
            tl.to(".home_page_data", {duration: 3, css: {transform: "scale(150)"}, ease:"Power2.easeInOut"}, "-=0.5");
            tl.to(".home_page_img", {duration: 2, css: {transform: "scale(1)"}, ease:"Power4.easeInOut"}, "-=3");
            tl.to(".home_page_border", {duration: 1, css: {transform: "scale(10)"}, ease:"Power4.easeInOut"}, "-=2.5");
            tl.set(".rupture_Page", {css:{zIndex: 2 }}, "-=1.5");
            tl.to(".rupture_Page_data", {duration: 1.5, css: {opacity: 1}, ease:"Power4.easeInOut"}, "-=2.5");
        },
    }
}

</script>

<style lang="scss" scoped>
.home_page {

    .grow {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.15s;
        transition-duration: 0.15s;
        -webkit-transition-property: transform;
        transition-property: transform;
    }

    .grow:hover, .grow:focus, .grow:active {
        -webkit-transform: scale(1.15);
        transform: scale(1.15);
    }

    &_rupture {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    &_border {
        position: absolute;
        z-index: 1;
        background-image: url("../assets/img/PORTE.png");
        min-width: 100%;
        min-height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &_data {
        z-index: 1;
        width: -webkit-fill-available;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        white-space: nowrap;

        &_list {
            font-size: 1.5rem;
            display: flex;
            justify-content:space-around;
            width: 100vw;
            padding: 0 10vw;
        }
    }
    a {
        color: #000;
        text-decoration: none;
    }

    &_item {
        margin-left: 1.5rem;
    }
    
    &_description {
        font-size: 1.5rem;
    }
}

h1 {
    padding-top: 1.5rem;
}

h1, h2 {
    font-size: 6rem;
}

.move {
    perspective: 600px;
    display: flex;
    justify-content: center;
}

// Responsive HomePage

@media only screen and (min-width : 1224px) {
    .home_page_data_list {
        padding: 15vh 10vw;
    }

    h1, h2 {
        font-size: 8rem;
    }
}

@media screen and (min-width: 200px) and (max-width: 640px) {
    .home_page_data  {
        height: 100vh;
        justify-content: space-between;
        
        &_list {
            font-size: 0.75rem;
            flex-direction: column;
            align-items: center
        }
        a {
            padding: 1rem 0;
        }
    }
    h1, h2 {
        font-size: 24vw;
    }
    h1 {
        padding-top: 3vh;
    }
    .home_page_item {
        margin-top: 1.25vh;
        margin-left: 0;
        font-size: 1.25rem;
    }
    .home_page_item:first-of-type a[data-v-b7d146bc] {
        margin-left: 0; 
        margin-top: 0;
    }

    .home_page_description {
        font-size: 1.25rem;
        position: relative;
        top: -23vh;
    }
}
</style>

<template>
  <div class="rupture_Page">

    <div class="rupture_Page_data">
      <div class="rupture_Page_title">
        <router-link to="/"><h1 class="rupture_Page_title_name" @click="animRuptureToHome" >Airmow</h1></router-link>
        <h2 class="rupture_Page_title_description">Rupture</h2>
      </div>

      <ul class="rupture_Page_list">
        <li v-for="(music, i) in musics" 
            :key="i"
            class="rupture_Page_item"
            ref="music" 
            @click="onClick(music, i)">
          {{ music.name }}
        </li>
      </ul>

      <AudioManager :music="music" @onEnded="onEnded" @startMusic="startMusic" @onNextPrevious="onNextPrevious" />
    </div>
    
  </div>
</template>

<script>
import AudioManager from '@/components/AudioManager.vue'
import gsap from 'gsap'

export default {
  name: 'RupturePage',
  components: {
    AudioManager,
  },
  data() {
    return {
      activated: false,
      callEventListener: "",
      music: {},
      musics: [
        { name: "NOW I'M JUST A GHOST ft. SilentChild", src: "https://musics.airmow.fr/musics/NOW_I_M_JUST_A_GHOST.mp3" },
        { name: "MAKE ME BREAK ft. Trove", src: "https://musics.airmow.fr/musics/MAKE_ME_BREAK.mp3" },
        { name: "LIKE THIS", src: "https://musics.airmow.fr/musics/LIKE_THIS.mp3" }, 
        { name: "YOU KNOW THAT ft. Leo", src: "https://musics.airmow.fr/musics/YOU_KNOW_THAT.mp3" },
        { name: "WILD NIGHT ft. Frizzy The Streetz", src: "https://musics.airmow.fr/musics/WILD_NIGHT.mp3" },
        { name: "CALL ME ft. blurblur", src: "https://musics.airmow.fr/musics/CALL_ME.mp3" }
      ]
    }
  },

  methods: {
      onEnded: function(value) {
        if (value.index !== this.musics.length - 1) {
          const data = {
            name: this.musics[value.index + 1].name,
            src: this.musics[value.index + 1].src,
            index: value.index + 1
          }
          this.music = data
          this.lineThroughAppear(this.music.index)
        }
        else {
          const data = {
            name: this.musics[0].name,
            src: this.musics[0].src,
            index: 0
          }
          this.music = data
          this.lineThroughAppear(this.music.index)
        }
      },
      onClick: function(value, index) { 
        const data = {
          name: value.name,
          src: value.src,
          index
        }
        this.music = data
        this.lineThroughAppear(index)
      },

      startMusic: function(value) {
        this.lineThroughAppear(value.index)
      },

      onNextPrevious: function(value) {
        let index = value.music.index
        value.event === "next" ? index++ : index--

        if (index < this.musics.length && index > 0) {
          const data = {
            name: this.musics[index].name,
            src: this.musics[index].src,
            index: index
          }
          this.music = data 
        } 
        else {
          const data = {
            name: this.musics[0].name,
            src: this.musics[0].src,
            index: 0
          }
          this.music = data
          index = 0
        }
        this.lineThroughAppear(index)
      },

      lineThroughAppear: function(index) {
        this.$refs.music.forEach(element => {
          element.classList.remove("active")
        });

        this.$refs.music[index].classList.add("active")
      },

      animRuptureToHome: function () {
          let tl = gsap.timeline()
          tl.to(".rupture_Page_data", {duration: 0.5, css: {opacity: 0}, ease:"Power4.easeInOut"});
          tl.to(".rupture_Page", {duration: 1, css: {opacity: 0}, ease:"Power4.easeInOut"});

          tl.to(".home_page_img", {duration: 0.5, css: {opacity:1}, ease:"Power4.easeInOut" }, "-=1.25");
          tl.set(".rupture_Page", {css:{zIndex: -1 }}, "-=0.45");
          tl.to(".home_page_border", {duration: 1, css: {transform: "scale(1)"}, ease:"Power4.easeInOut"}, "-=1");
          tl.to(".home_page_data", {duration: 3, css: {transform: "scale(1)"}, ease:"Power2.easeInOut"}, "-=2.5");

          setTimeout(() => {
            this.$emit("ChangeData", );
          }, 3000);
      },
  },

  created() {
    const data = {
      name: this.musics[0].name,
      src: this.musics[0].src,
      index: 0
    }
    this.music = data
  }

}
</script>

<style lang="scss" scoped>

.active {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 115%;
    height: 3px;
    background-color: #000;
  }
}

.rupture_Page {
  position: absolute;
  z-index: -5;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #fff;
  background-size: cover;
  background-position: right 50%;
  background-repeat: no-repeat;
  color: #000;
  font-weight: bold;
  
  &_data {
    opacity: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 3rem;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  &_title  {
    line-height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_name {
      font-size: 8rem;
    }

    &_description {
      font-size: 2rem;
    }
  }

  &_list {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: initial;
    text-align: center;
  }

  &_item {
    margin-top: 2vh;
    width: fit-content;

    &:first-child {
      margin-top: 0;
    }
    
    &:hover {
      cursor: pointer;
    }
  }

  a {
    color: #000;
    text-decoration: none;
  }
}
.audioManager {
  width: 90vw;
}

@media screen and (min-width: 200px) and (max-width: 640px) {
    .rupture_Page {

      &_data {
        padding: 0;
      }

      &_list {
        max-width: 100vw;
        font-size: 1rem;
      }
      &_item {
        margin-top: 4vh;
      }
      &_title {
        line-height: initial;

        &_name {
          font-size: 24vw;
        }
      }
    }
}
</style>
